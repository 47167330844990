import React from "react";
import theme from "theme";
import { Theme, Text, Box, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Caffetteria CozyPlace
			</title>
			<meta name={"description"} content={"Caffetteria CozyPlace si distingue nel mare delle caffetterie per il suo impegno per la qualità, la comunità e la creatività."} />
			<meta property={"og:title"} content={"Caffetteria CozyPlace"} />
			<meta property={"og:description"} content={"Caffetteria CozyPlace si distingue nel mare delle caffetterie per il suo impegno per la qualità, la comunità e la creatività."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section
			padding="90px 0 120px 0"
			lg-margin="0 0 0 0"
			lg-padding="80px 0 80px 0"
			background="#ffffff"
			quarkly-title="Price-20"
		>
			<Text margin="0px 0px 20px 0px" font="--lead" text-align="center">
				Esplora il menu del Caffetteria CozyPlace Coffee House
			</Text>
			<Text
				font="--headline1"
				color="--darkL1"
				lg-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 10px 0px"
				margin="0px 0 4rem 0"
				width="100%"
				md-width="100%"
				md-margin="0 0 20px 0"
				text-align="center"
			>
				Il nostro menù
			</Text>
			<Text
				font="--headline2"
				color="--darkL1"
				lg-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 10px 0px"
				margin="0px 0 2rem 0"
				width="100%"
				md-width="100%"
				md-margin="0 0 20px 0"
				text-align="center"
			>
				Caffè curati
			</Text>
			<Box padding="0px 120px 0px 120px" lg-flex-direction="column" lg-padding="0px 0 0px 0">
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Moka eccentrica
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Una ricca miscela di caffè espresso, cioccolato e cannella
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 5.50
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="normal 600 36px/1.3 --fontFamily-sansTrebuchet"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						<Strong font="--headline3">
							Latte stravagante
						</Strong>
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Infuso con sciroppo di vaniglia fatto in casa
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 5.00
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Birra dell'esploratore
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Caffè monorigine a rotazione
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 6.00
						</Text>
					</Box>
				</Box>
				<Text
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 10px 0px"
					margin="0px 0 2rem 0"
					width="100%"
					md-width="100%"
					md-margin="0 0 20px 0"
					text-align="center"
				>
					Tè e infusi artigianali
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						<Strong>
							Miscela di erbe lenitiva
						</Strong>
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Camomilla, lavanda e menta
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 4.50
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Tè verde Zen
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Selezione di tè verde premium
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 4.50
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Avventura Chai
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Miscela piccante di chai
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 5.00
						</Text>
					</Box>
				</Box>
				<Text
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 10px 0px"
					margin="0px 0 2rem 0"
					width="100%"
					md-width="100%"
					md-margin="0 0 20px 0"
					text-align="center"
				>
					Delizie dolci e salate
					<br />
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						<Strong>
							Croissant innovativi
						</Strong>
						{" "}
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Cioccolato Mandorle / Prosciutto e Formaggio
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 6.50
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Panini Gourmet
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Brie di tacchino, mele e hummus di avocado vegetariano
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							<Strong>
								EUR 10.00
							</Strong>
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						<Strong>
							Dessert decadenti
						</Strong>
						{" "}
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Brownie al triplo cioccolato / Crostata alla frutta di stagione
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 7.00
						</Text>
					</Box>
				</Box>
				<Text
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 10px 0px"
					margin="0px 0 2rem 0"
					width="100%"
					md-width="100%"
					md-margin="0 0 20px 0"
					text-align="center"
				>
					Opzioni vegane e senza glutine
					<br />
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Wrap vegetariano vibrante
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Con una tortilla senza glutine.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 9.00
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Frullato di bacche delizioso
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Senza latte con latte di mandorle.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							EUR 7.50
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Torta senza glutine
						</Strong>
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							Con cioccolato.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--lead" text-align="left">
							<Strong>
								EUR 7.00
							</Strong>
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 20px 0px" font="--lead" text-align="center">
				Questo menu è solo l'inizio di ciò che offriamo a Caffetteria CozyPlace. Ad ogni visita troverai nuovi sapori da esplorare e storie da scoprire. Se hai preferenze dietetiche o hai bisogno di alloggio, non esitare a contattarci. Siamo qui per garantire che la tua esperienza sia piacevole e su misura per le tue esigenze.
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});